@import '/src/index.css';

.app__home {
  width: 100%;
  max-width: 100%;
  padding: 1.4rem 0 0;
  background-color: #f2eee9;
}
/*  */

.countdown__block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--c-w);
  row-gap: 2rem;
  padding: 2rem 4rem;
  border-radius: 1.2rem;
  max-width: 85%;
}
.countdown__innerblock {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  /* max-width: 430px; */
}
.individual__countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: center;
  row-gap: 0.5rem; */
}
.countdown__header {
  color: #445689;
  font-weight: 800;
  font-size: 1.3rem;
  letter-spacing: 0.19rem;
}
.countdown__type {
  color: #c8c8c8;
  font-size: 0.9rem;
  font-weight: 300;
}
.countdown__number {
  color: #4d4c59;
  font-size: 4.1rem;
}
.countdown__number__gap {
  color: #4d4c59;
  font-size: 4.5rem;
  text-align: center;
}
.countdown__prizeicon {
  position: absolute;
  top: 1rem;
  left: -2rem;
  /* animation: fadeInFromTop 0.8s linear forwards, floating ease 4s infinite; */
  animation: float 6s ease-in-out infinite;
}

.countdown__chaticon {
  position: absolute;
  top: -3.7rem;
  right: -2rem;
  /* animation: fadeInFromTop 0.5s linear forwards, floating ease 4s infinite; */
  animation: floatChat 6s ease-in-out infinite;
}
.countdown__peopleicon {
  position: absolute;
  bottom: -10rem;
  right: -3.7rem;
  width: 46%;
  animation: floatChat 6s ease-in-out infinite;
}
.advantage__video {
  width: 450px;

  border-radius: 0.8rem;
  overflow: hidden;
  max-width: 100%;
}
.advantage__video iframe {
  width: 100%;
  height: 290px;
}
.wave__border {
  display: block;
  background-color: var(--black-c);
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-14px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes floatChat {
  0% {
    /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translateX(0px);
  }
  50% {
    /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
    transform: translateX(-13px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floating {
  0% {
    transform: translate(0%, 0%) rotate(360deg);
  }
  25% {
    transform: translate(5%, 15%) rotate(360deg);
  }
  50% {
    transform: translate(10%, 5%) rotate(360deg);
  }
  75% {
    transform: translate(0%, 15%) rotate(360deg);
  }
  100% {
    transform: translate(0%, 0%) rotate(360deg);
  }
}
.wave__border {
  display: block;
  background-color: #000;
}

/* Section 1 Starts */

.home__section1 {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 3rem auto 0rem;
  padding: 0.8rem 0 4rem;
  background-color: #f2eee9;
}

.section1__center {
  width: 1200px;
  max-width: 100%;
  margin: auto;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem 1rem;
}

.section1__side1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2.4rem;
}

.section1__side1 h1 {
  color: #1d1d1d;
  font-family: var(--f-p);
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -0.84px;
}

.section1__side1 h1 span {
  /* background-image: url('../../assets/TextBG.svg'); */
  background: linear-gradient(134deg, #455689 50%, #92d3da 60%, #455689 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased; */
}

.section1__side1 .side1__btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
}

.side1__btns .btns__main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
}

.btns__main button {
  text-align: center;
  padding: 0.7rem 2rem;
  border-radius: 12px;
  border: 0;

  line-height: normal;
  cursor: pointer;
  background: linear-gradient(134deg, #92d3da 0%, #455689 100%);
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
  transition: background 0.4s ease;
}
.btns__main button:hover {
  background: #455689;
}
.btns__main button a {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}
.side1__btns p {
  color: #384d6c;
  font-family: var(--f-p);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 166.667% */
  letter-spacing: -0.36px;
}

.section1__side2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

@keyframes icon1 {
  0% {
    transform: translateX(0px);
  }

  33.33% {
    transform: translate(-6px, 4px);
  }

  66.66% {
    transform: translate(6px, -8px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes icon2 {
  0% {
    transform: translate(0px);
  }

  33.33% {
    transform: translate(-6px);
  }

  66.66% {
    transform: translate(6px);
  }

  100% {
    transform: translate(8px);
  }
}

/* Section 1 Ends */

/* Section 2 Starts */

.home__section2 {
  width: 100%;
  max-width: 100%;
  background-color: #000000;
}

.section2__center {
  width: 1200px;
  max-width: 100%;
  padding: 1rem 1rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}

.section2__center h2 {
  color: #fff;
  font-family: var(--f-p);
  font-size: 32px;
  font-style: normal;
  /* text-align: center; */
  font-weight: 600;
  letter-spacing: -0.48px;
}

.section2__main {
  width: 100%;
  padding: 1.2rem;
  border-radius: 23px;
  background: #f2eee9;
  backdrop-filter: blur(9px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 2rem;
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;
  /* gap: 0.4rem 1rem; */
}

.section2__main img {
  object-fit: contain;
  aspect-ratio: auto;
  height: 100px;
  /* width: 80%; */
}

/* Section 2 Ends */

/* Section 3 Starts */

.home__section3 {
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.section3__center {
  width: 1200px;
  max-width: 100%;
  padding: 4rem 1rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.section3__side1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.2rem;
}

.section3__side1 h2 {
  color: #fff;
  font-family: var(--f-p);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.48px;
}

.section3__side1 h2 span {
  background-image: url('../../assets/TextBG.svg');
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
}

.section3__side1 p {
  color: #fff;
  font-family: var(--f-p);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: -0.3px;
}

.section3__side2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section3__side2 img {
  width: 90%;
}

/* Section 3 Ends */

/* Section 4 Starts */

.home__section4 {
  width: 100%;
  max-width: 100%;
  background-color: #000000;
  padding: 2rem 0.6rem;
}

.section4__center {
  width: 1200px;
  max-width: 100%;
  padding: 1rem 1.4rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem 2rem;
  border-radius: 20px;
  border: 0.862px solid #152329;
  background: linear-gradient(
    161deg,
    #000 11.46%,
    rgba(79, 169, 226, 0.33) 99.99%,
    rgba(255, 255, 255, 0.5) 100%
  );
}

.section4__side1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.section4__side1 img:nth-child(1) {
  width: 180px;
  height: 150px;
  transform: translate(30px, 40px);
}

.section4__side1 img:nth-child(2) {
  width: 180px;
  height: 150px;
  transform: translate(-40px, -20px);
}

.section4__side2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.6rem;
}

.section4__side2 h4 {
  color: #fff;
  font-family: var(--f-p);
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 267.711% */
  letter-spacing: -0.48px;
}

.section4__side2 p {
  color: #fff;
  font-family: var(--f-p);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.3px;
}
.section4__side2__flex {
  display: flex;
  column-gap: 1.4rem;
}
/* Section 4 Ends */

/* Section 5 & 6 Starts */

.home__section5,
.home__section6 {
  width: 100%;
  max-width: 100%;
  background-color: #000000;
  padding: 2rem 1rem;
}

.section5__center {
  width: 1200px;
  max-width: 100%;
  margin: auto;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.section6__center {
  width: 1200px;
  max-width: 100%;
  margin: auto;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.section5__center video,
.section6__center video {
  width: 70%;
  height: 400px;
  /* aspect-ratio: 16/9; */
  border: none;
}

.section5__main,
.section6__main {
  width: 420px;
  max-width: 100%;
  padding: 1.2rem 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.4rem;
  border-radius: 8px;
  background: #f2eee9;
  position: absolute;
  right: 5%;
}

.section6__main {
  right: 0;
  left: 2%;
}

.section5__main h3,
.section6__main h3 {
  font-family: var(--f-p);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px; /* 267.711% */
  letter-spacing: -0.48px;
  background: linear-gradient(
    134deg,
    #000 11.46%,
    #455689 40.63%,
    #455689 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section5__main p,
.section6__main p {
  color: #232323;
  font-family: var(--f-p);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.section5__main button,
.section6__main button {
  text-align: center;
  padding: 0.7rem 2rem;
  border-radius: 12px;
  border: 0;
  line-height: normal;
  cursor: pointer;
  background: linear-gradient(134deg, #92d3da 0%, #455689 100%);
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
  transition: background 0.4s ease;
}
.section5__main button:hover,
.section6__main button:hover {
  background: #455689;
}
.section5__main button a,
.section6__main button a {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

/* Section 5 & 6 Ends */

/* Section 7 Starts */

.home__section7 {
  position: relative;
  width: 100%;
  max-width: 100%;
  background-color: #000000;
  padding: 4rem 1rem;
  overflow: hidden;
  z-index: 10;
}
.section7__gradient {
  max-width: 100%;
  height: 1150px;
  max-height: 100%;
  position: absolute;
  top: 0.3rem;
  right: -20rem;
  /* background-color: #000; */
}
.section7__center {
  width: 1200px;
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section7__form {
  width: 600px;
  max-width: 100%;
  display: flex;
  padding: 2rem 2rem;
  border-radius: 12px;
  background: linear-gradient(
    180deg,
    rgba(215, 255, 253, 0.8) 0.52%,
    rgba(255, 255, 255, 0.4) 100%
  );
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
  position: relative;
  z-index: 20;
}

.section7__form h3 {
  font-family: var(--f-p);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(
    134deg,
    #000 16.15%,
    #455689 79.17%,
    #455689 98.44%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form__ins {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;
  align-items: center;
}

.form__ins input {
  width: 90%;
  border-radius: 8px;
  border: none;
  padding: 0.6rem 1rem;
  outline: none;
  font-family: var(--f-p);
  font-size: 18px;
}

.section7__form button {
  text-align: center;
  padding: 0.7rem 2rem;
  border-radius: 12px;
  border: 0;
  color: #fff;
  font-family: var(--f-p);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  background: linear-gradient(134deg, #92d3da 0%, #455689 100%);
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
  transition: background 0.4s ease;
  max-height: 45px;
  position: relative;
}

.section7__form button:hover {
  background: #455689;
}
.loading__icon {
  /* position: absolute; */
  height: 150px;
  margin: -3.3rem 0;
  width: 200px;
}

.section7__form > p {
  color: #fff;
  text-align: center;
  /* font-family: var(--f-p); */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transform: translateY(10px);
}

.section7__form img {
  position: absolute;
  width: 64px;
}

.section7__form .icon1 {
  top: 13rem;
  left: -3rem;
  animation: float 6s linear infinite;
}

.section7__form .icon2 {
  right: -5px;
  bottom: 4rem;
  animation: floatChat 6s linear infinite;
}
.section7__form .icon3 {
  top: 4rem;
  left: -5rem;
  animation: floatChat 6s linear infinite;
}

.section7__form .icon4 {
  right: -5rem;
  bottom: 8rem;
  animation: floatChat 6s linear infinite;
}
@keyframes iconmove {
  0% {
    transform: translateY(2px);
  }

  33% {
    transform: translateY(4px);
  }

  66% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(2px);
  }
}

/* Section 7 Ends */

/* Section 8 Starts */

.home__section8 {
  width: 100%;
  max-width: 100%;

  background-color: #000000;
  padding: 2rem 1rem;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.section8__gradient {
  width: 100%;
  height: 700px;
  position: absolute;
  top: -1rem;
  left: 3rem;
  border-radius: 10rem;
  z-index: 0;
}
.section8__center {
  width: 1200px;
  max-width: 100%;
  margin: auto;
  padding: 2rem 0;
  z-index: 20;
}

.section8__center h2 {
  text-align: center;
  color: var(--white, #fff);
  font-family: var(--f-p);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 44.8px */
  letter-spacing: 1.2px;
  text-transform: capitalize;
}

.faq__main {
  position: relative;
  z-index: 20;
  width: 600px;
  max-width: 100%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
}

.faq__box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0rem;
  border: 2px solid var(--black, #152329);
  backdrop-filter: blur(7.5px);
}

.faq__box.active {
  gap: 0.4rem;
}

.q__box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 0 0 0.8rem;
  align-items: flex-start;
  gap: 0.4rem;
}

.q__box div {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  padding: 0.8rem 0 0 0;
}

.q__box span {
  color: var(--white, #fff);
  font-family: var(--f-p);
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.q__box p {
  color: var(--white, #fff);
  font-family: var(--f-p);
  font-size: 18px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.q__box button {
  padding: 0.6rem 1.2rem;
  font-size: 24px;
  height: 100%;
  border-radius: 0 4px 4px 0;
  border: none;
  color: #fff;
  background: var(--black, #152329);
  backdrop-filter: blur(7.5px);
  transition: all 0.3s linear;
  cursor: pointer;
}

.q__box button:hover,
.q__box button.active {
  background-color: #4fa9e2;
}

.a__box::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.a__box {
  width: 100%;
  height: 0;
  overflow-y: hidden;
  transition: height 0.5s ease-in-out;
}

.a__box.active {
  height: fit-content;
  padding-bottom: 3px;
  transition: height 0.5s ease-in-out;
}
/* Add this to your CSS */

.a__box p {
  width: 100%;
  padding: 1rem 1.2rem;
  color: var(--white, #fff);
  font-family: var(--f-p);
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

/* Section 8 Ends */

/* ************************************************************************* */

/* Media Queries */

@media all and (max-width: 1200px) {
  /* Section 1 Starts */

  .section1__side1 h1 {
    font-size: 44px;
    width: 100%;
  }
  .section1__side2 {
    max-width: 70%;
  }

  .countdown__block {
    max-width: 76%;
    row-gap: 1rem;
  }
  .countdown__number {
    font-size: 3.8rem;
  }
  .countdown__header {
    text-align: center;
    /* width: 100%; */
  }

  .countdown__peopleicon {
    bottom: -8.2rem;
  }
  /* Section 1 Ends */
}

@media all and (max-width: 960px) {
  /* Section 1 Starts */

  .section1__center {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .section1__side2 {
    width: 100%;
  }
  .countdown__block {
    width: 80%;
  }

  .section1__side1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 2;
  }

  .section1__side2 {
    order: 1;
  }
  .section1__side1 .side1__btns .btns__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .section1__side1 .side1__btns p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    width: 100%;
  }

  .hero__header {
    text-align: center;
    width: 80%;
  }
  .section1__side1 

  /* Section 1 Ends */

  /* Section 5 & 6 Starts */

  .section5__center video,
  .section6__center video {
    width: 100%;
  }
  .section5__center video {
    width: 100%;
  }

  .section5__main,
  .section6__main {
    right: 2rem;
    bottom: 0;
  }

  /* Section 5 & 6 Ends */
}
@media all and (max-width: 850px) {
  .countdown__block {
    min-width: 90%;
  }
}
@media all and (max-width: 740px) {
  .section1__center {
    padding: 0 0;
  }
  .countdown__block {
    min-width: 100%;
  }
  .section1__side2 {
    width: 400px;
  }
  .section2__main img {
    height: fit-content;
    width: 30%;
  }
  .section4__side2__flex {
    display: flex;
    flex-direction: column;
    row-gap: 1.4rem;
    /* margin-left: 1rem; */
  }
  .section4__side1 {
    width: 120%;
  }
  .section7__form .icon4 {
    right: -0.2rem;
  }
  .section7__form .icon2 {
    right: 2rem;
  }
}
@media all and (max-width: 700px) {
  .section3__center {
    flex-direction: column-reverse;
  }
  .section3__side2 img {
    width: 75%;
  }
  .section5__main,
  .section6__main {
    right: 1rem;
    bottom: -0rem;
    width: 380px;
    /* height: 300px; */
  }
  .section7__form .icon1 {
    left: 1rem;
    top: 16rem;
  }
  .section7__form .icon3 {
    left: -0.3rem;
  }
}
@media all and (max-width: 600px) {
  /* Section 3 Starts */
  .countdown__header {
    letter-spacing: 0.12rem;
  }
  .section3__side1 h2 {
    font-size: 28px;
    line-height: normal;
  }
  .section1__center {
    padding: 0 0;
  }

  .countdown__block {
    min-width: 110%;
  }
  .countdown__number {
    font-size: 3.1rem;
  }
  .section3__side1 p {
    font-size: 18px;
  }

  .section3__side2 img {
    width: 80%;
  }
  .section7__gradient {
    right: 0;
  }
  .section8__gradient {
    left: 0;
  }

  /* Section 3 Ends */

  /*  */
  .section4__center {
    flex-direction: column;
  }
  /*  */

  /* Section 7 Starts */

  .form__ins input {
    width: 100%;
  }
  .section7__form .icon3 {
    /* left: -0.3rem; */
    top: 1.2rem;
  }
  /* Section 7 Ends */

  /* Section 8 Starts */

  .q__box div {
    padding: 0.8rem 0.4rem;
  }

  .q__box span {
    display: none;
  }
  .section5__center {
    flex-direction: column;
  }
  .section6__center {
    flex-direction: column-reverse;
  }
  .section5__center video,
  .section6__center video {
    height: fit-content;
    border-radius: 12px 12px 0 0;
  }
  .section6__center img {
    border-radius: 0;
  }
  .section5__main,
  .section6__main {
    position: static;
    width: 100%;
    border-radius: 0;
  }
  /* Section 8 Ends */
}

@media all and (max-width: 500px) {
  /* Section 1 Starts */
  .section1__center {
    align-items: center;
  }
  .section1__side2 {
    min-width: 100%;
  }
  .advantage__video {
    width: 90% !important;
  }
  .countdown__block {
    max-width: 100%;
    /* margin: 0; */
  }
  .countdown__number {
    font-size: 2.3rem;
  }
  .countdown__type {
    font-size: 0.65rem;
  }
  .countdown__chaticon {
    width: 100px;
  }
  .countdown__header {
    font-size: 1rem;
  }
  .section1__center {
    padding: 0 0rem;
  }

  .section1__side1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section1__side1 h1 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 800;
    color: var(--black-c);
  }

  .section1__side1 .side1__btns .btns__main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .section1__side1 .side1__btns p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
  /* .countdown__block {
    min-width: 130%;
  } */

  /* Section 1 Ends */

  /* Section 2 Starts */

  .section2__center h2 {
    font-size: 22px;
  }

  .section2__main img {
    width: 30%;
  }

  /* Section 2 Ends */

  /* Section 4 Starts */

  .section4__center {
    gap: 2rem;
  }

  .section4__side2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .section4__side2 h4 {
    font-size: 24px;
  }

  .section4__side2 p {
    font-size: 16px;
  }

  /* Section 4 Ends */

  /* Section 5 & 6 Starts */

  .section5__main,
  .section6__main {
    position: static;
    left: 0;
    transform: none;
    padding: 1rem;
  }
  .countdown__peopleicon {
    display: none;
  }

  /* Section 5 & 6 Ends */

  /* Section 8 Starts */

  .q__box p {
    font-size: 17px;
  }

  .a__box p {
    font-size: 15px;
    line-height: normal;
  }

  /* Section 8 Ends */
  /*  */
  .section1__center {
    align-items: center;
  }
  .countdown__block {
    max-width: 100%;
    /* margin: 0; */
  }
  .countdown__number {
    font-size: 2.3rem;
  }
  .countdown__type {
    font-size: 0.65rem;
  }
  .countdown__chaticon {
    width: 100px;
  }
  .countdown__header {
    font-size: 1rem;
  }
  .loading__icon {
    height: 150px;
    margin: -2.5rem 0 -4rem 0;
  }
}

@media all and (max-width: 450px) {
  /* Section 2 Starts */

  /* Section 2 Ends */

  /* Section 3 Starts */

  .section3__side1 h2 {
    font-size: 26px;
    text-align: center;
  }

  .section3__side1 p {
    font-size: 17px;
    text-align: center;
  }

  .section3__side2 img {
    width: 100%;
  }
  .section7__form .icon1,
  .section7__form .icon4 {
    display: none;
  }
  .section7__form .icon3 {
    animation: float;
  }

  /* Section 3 Ends */

  /* Section 7 Starts */

  .section7__form {
    padding: 1.8rem 1rem;
  }

  /* Section 7 Ends */
}

@media all and (max-width: 400px) {
  .countdown__number {
    font-size: 1.8rem;
  }
}
@media all and (max-width: 360px) {
  /* Section 1 Starts */

  .countdown__innerblock {
    column-gap: 10px;
  }
  .countdown__number {
    font-size: 1.6rem;
  }
  .countdown__header {
    font-size: 1rem;
    letter-spacing: 0.2px;
    padding: 0;
    margin: 0;
  }

  .section1__side1 h1 {
    font-size: 28px;
    line-height: normal;
  }
  .countdown__innerblock {
    column-gap: 10px;
  }
  .countdown__number {
    font-size: 1.6rem;
  }
  .countdown__header {
    font-size: 1rem;
    letter-spacing: 0.2px;
    margin: 0;
    padding: 0;
  }

  /* Section 1 Ends */
}
