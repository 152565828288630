@import '/src/index.css';

.page_container{
    margin:3vh 10vw ;
}

.community_grid{
    margin-top: 5vh;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    column-gap: 5vw;
}
.community_card{
    border: 2px black solid;
    border-radius: 15px;
    padding: 3vh;
    background-color: white;
    filter: drop-shadow(6px 0px 0px black);
}
.communitycover{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}
.community_card h2{
    padding-bottom: 1vh;
}
.communitycover p{
    font-size: 1.2em;
}
.community_card img{
    background-color: black;
    border-radius: 15px;
    height: 20vh;
}
.tags{
    display: flex;
    margin-bottom: 2vh;
}
.community_card h4{
    padding-bottom: 1vh;
}

.tag_item{
    font-size:0.7em;
    color: white;
    background-color: rgba(52, 87, 123, 0.68);
    border-radius: 10px;
    padding: 1vh 1vw;
    margin-right: 0.5vw;
    width:auto;
}
.explore_btn{
    padding: 2vh;
    border-radius: 15px;
    /* background-color: black; */
    background: linear-gradient(134deg, #92d3da 0%, #455689 100%);
    color: white;
    border: none;
    width: 100%;
    cursor: pointer;
}

.explore_btn:hover {
    background: #455689;
  }

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    position: relative;
  }
  
  .modal_content {
    text-align: center;
  }
  
  .close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  