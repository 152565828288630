@import '/src/index.css';
/* Hero Section -Section 1 */
.community__waitlist__page {
  width: 100%;
  padding: 1.4rem 0 0;
  background-color: #f2eee9;
  margin-bottom: -4rem;
}
.hero__section {
  width: 1200px;
  max-width: 100%;
  padding: 1rem 1rem;
  margin: -5rem auto 2rem auto;
  display: flex;
  align-items: center;
  height: 600px;
  /* overflow: hidden; */
  overflow-x: visible;
  z-index: 50;
  position: relative;
}

.hero__section__left {
  width: 54%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}
.hero__section__left h1 {
  font-size: 2.3rem;
  font-weight: 800;
  color: #0f415e;
}

.hero__section__left h1 span {
  background: linear-gradient(134deg, #455689 50%, #92d3da 60%, #455689 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero__section__left p {
  font-size: 1.2rem;
  font-weight: 600;
  color: #0f415e;
  letter-spacing: 0.3px;
}
.hero__section__left a button {
  text-align: center;
  padding: 0.6rem 2.4rem;
  border-radius: 12px;
  border: 0;
  color: #fff;
  font-family: var(--f-p);
  font-size: 1rem;
  letter-spacing: 0.3px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  width: 9.7rem;
  background: linear-gradient(134deg, #92d3da 0%, #455689 100%);
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
  transition: background 0.4s ease;
}
.hero__section__left a button:hover {
  background: #455689;
}

.hero__section__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 50%;
  margin-top: -3.2rem;
  z-index: 20;
}
.hero__section__right img {
  width: 460px;
  height: 460px;
}

/* Countdown CSS */
.countdown__block {
  position: absolute;
  bottom: -5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--c-w);
  row-gap: 1.2rem;
  padding: 2rem 4rem;
  border-radius: 1.2rem;
  /* max-width: 60%; */
  width: 350px;
}
.countdown__innerblock {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
}
.individual__countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* align-items: center;
  row-gap: 0.5rem; */
}
.countdown__header {
  color: #445689;
  font-weight: 800;
  font-size: 1rem;
  letter-spacing: 0.19rem;
  width: 100%;
  text-align: center;
}
.countdown__type {
  color: #c8c8c8;
  font-size: 0.9rem;
  font-weight: 300;
}
.countdown__number {
  color: #4d4c59;
  font-size: 3rem;
}

/* Countdown CSS */

/* Section 2 Benifits  */
.section2__benefit div img {
  width: 40px;
  height: 40px;
}
.section2__benefit div {
  padding: 1rem 1rem 0.5rem 1rem;
  /* border: 1px solid black; */
  width: fit-content;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.15) inset;
}
.section2__benefit p {
  color: #0f415e;
  font-family: DM Sans;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-left: 1rem;
}

.section2 {
  margin: 10rem 0;
  position: relative;
  width: 100%;
  max-width: 100%;

  z-index: 10;
}
.pink__gradient {
  max-width: 100%;
  width: 1200px;
  height: 650px;
  position: absolute;
  top: -26rem;
  right: 1rem;
  overflow: hidden;
}
.pink__gradient2 {
  max-width: 100%;
  width: 1200px;
  height: 650px;
  position: absolute;
  top: -15rem;
  left: -20rem;
}
.section2__block {
  width: 1200px;
  max-width: 100%;
  padding: 1rem 1rem;
  margin: -5rem auto 2rem auto;
  overflow: hidden;
}
.section2__block h6 {
  color: #0f415e;
  font-family: DM Sans;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  margin-bottom: 1rem;
  margin-left: 0.6rem;
}
.section2__innerblock {
  display: flex;
  column-gap: 5%;
  flex-wrap: wrap;
  flex-direction: row;
}
.section2__benefit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  border-radius: 23px;
  border: 1px solid #fff;
  background: linear-gradient(
    142deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(9px);
  padding: 1.2rem;
}

/* -----Section 2------ */

/* Section 3 */
.section3 {
  margin: 7rem 0;
}
.section3__block {
  width: 1200px;
  max-width: 100%;
  padding: 1rem 1rem;
  margin: -5rem auto 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.section3__innerblock {
  display: flex;
  column-gap: 5rem;
}

.section3__block p {
  color: #0f415e;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.3px;
}
.section3__left h2 {
  background: linear-gradient(134deg, #455689 50%, #92d3da 60%, #455689 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: DM Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
  width: 100%;
}
.section3__right p {
  color: #0f415e;
  font-family: DM Sans;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
/* Section 3 */
/*  Section 4 */

.section4__block {
  width: 1200px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 2.5rem;
  flex-direction: column;
  max-width: 100%;
  padding: 0rem 1rem;
  margin: 3rem auto 4rem auto;
  background: linear-gradient(
    to bottom right,
    black 0%,
    #000000 24%,
    #455689 100%
  );
  background-color: #000000;
}
.section4__block h2 {
  background: linear-gradient(134deg, #455689 50%, #92d3da 60%, #455689 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.32px;
}
.section4__form {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}
.section4__community__info {
  display: flex;
  flex-direction: column;
  row-gap: 1.4rem;
}
.section4__lead__info {
  display: flex;
  flex-direction: column;
  row-gap: 1.4rem;
}
.section4__lead__info button:hover {
  background: #455689;
}
.loading__icon {
  /* position: absolute; */
  height: 150px;
  margin: -3.1rem 0 -3.4rem -3rem;
  width: 200px;
}
.section4__community__info input,
.section4__lead__info input {
  outline: none;
  background: transparent;
  width: 24rem;
  color: rgba(255, 255, 255, 0.84);
  font-size: 0.9rem;
  font-weight: 400;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.48);
}
.section4__community__info input::placeholder,
.section4__community__info label,
.section4__lead__info input::placeholder,
.section4__lead__info label {
  color: rgba(255, 255, 255, 0.84);
  font-size: 0.9rem;
  font-weight: 400;
}
.section4__block button {
  text-align: center;
  padding: 0.8rem 2.4rem;
  border-radius: 12px;
  border: 0;
  color: #fff;
  font-family: var(--f-p);
  font-size: 1rem;
  letter-spacing: 0.3px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  width: 9.7rem;
  background: linear-gradient(134deg, #92d3da 0%, #455689 100%);
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
  transition: 0.2s ease;
}
.section4__block button:hover {
  cursor: pointer;
  background-color: #455689;
}
/* Section 4 */
@media all and (max-width: 1050px) {
  .hero__section__right img {
    width: 400px;
    height: 400px;
  }

  .countdown__number {
    font-size: 2.3rem;
  }
  .countdown__type {
    font-size: 0.6rem;
  }
  .section3__innerblock {
    display: flex;
    column-gap: 4rem;
  }
}
@media all and (max-width: 840px) {
  .hero__section {
    flex-direction: column-reverse;
    margin-top: 6.3rem;
    margin-bottom: 1rem;
  }
  .hero__section__left {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 6.5rem;
  }
  .hero__section__right {
    width: 100%;
  }
  .hero__section__left h1 {
    text-align: center;
  }
  .hero__section__left p {
    text-align: center;
    width: 80%;
  }
  .section2__benefit {
    padding: 0.7rem 0.5rem;
  }
  .section3__right p {
    font-size: 1rem;
  }
  .section3__left h2 {
    font-size: 36px;
  }

  .section3 .section3__innerblock {
    display: flex;
    column-gap: 3rem;
  }
  /*  */
  .section4__community__info input,
  .section4__lead__info input {
    width: 18rem;
  }
  .section4__block h2 {
    text-align: center;
  }
}

@media all and (max-width: 700px) {
  .section3__innerblock {
    flex-direction: column;
    row-gap: 2rem;
  }
  .section3__left,
  .section3__right {
    width: 100%;
  }
  .section3__left h2 {
    width: 100%;
  }
}
@media all and (max-width: 600px) {
  .section2__innerblock {
    display: grid;
    grid-template-columns: 50% 45%;
    row-gap: 2rem;
  }
  .section2__benefit {
    width: 100%;
  }
  .section4__form {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    row-gap: 3rem;
  }
  .loading__icon {
    /* position: absolute; */

    margin: -1.6rem 0 -3.4rem -3.8rem;
  }
  .section4__form .section4__community__info,
  .section4__form .section4__lead__info {
    width: 100%;
  }
  .section4__block {
    height: 760px;
    padding-top: 2rem;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .section4__community__info input,
  .section4__lead__info input {
    width: 100%;
  }
  .section4__block button {
    padding: 10px 20px;
  }
}

@media all and (max-width: 550px) {
  .hero__section {
    flex-direction: column-reverse;
    margin-top: 7.7rem;
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 410px) {
  .section2__innerblock {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 2rem;
  }
  .hero__section__right {
    display: flex;
    flex-direction: column;
  }
  .hero__section__right img {
    position: none;

    width: 310px;
    height: 310px;
    margin-bottom: 2rem;
  }
  .countdown__block {
    width: 300px;
    margin-top: 2rem;
    margin-bottom: -1rem;
  }
  .countdown__header {
    font-size: 1rem;
    width: 120%;
  }
  .loading__icon {
    height: 150px;
    margin: -2.2rem 0 -3.4rem -3.8rem;
  }
}
@media all and (max-width: 376px) {
  .hero__section__left h1 {
    font-size: 2rem;
  }
}
