@import '/src/index.css';

.dashboard__sidebar {
  width: 280px;
  height: 100vh;
  background-color: #000;

  transition: width 0.3s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  border-right: 1.6px solid rgba(189, 197, 205, 0.5);
}

.dashboard__sidebar.active {
  width: 80px;
  transition: width 0.3s ease-in-out;
}

.sidebar__main {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  max-width: 100%;
  padding: 1.4rem 0.6rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
  position: relative;
}

.sidebar__main::-webkit-scrollbar {
  width: 0;
}

.sidebar__logo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
  position: relative;
}

.sidebar__logo img {
  width: 64px;
  height: 100%;
  object-fit: cover;
}

.sidebar__logo p {
  font-size: 17px;
  font-weight: 600;
  color: var(--c-p);
}

.sidebar__menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
}
.login__btn {
  background: transparent;
  color: #687684;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}
.sidebar__menu__container {
  position: absolute;
  top: 0;
  z-index: 999;
  margin: 1.2rem auto;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
  transition: display 0.3s ease;
}

.sidebar__menu__container__active {
  transition: display 2s ease;
}

.nav__bar {
  position: relative;
  z-index: 10;
}

.navbar__mobile {
  display: none;
}

.sidebar__menu li {
  width: 100%;
  padding: 0 1rem;
}

.sidebar__menu li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  color: #687684;

  transition: color 0.3s ease-in-out;
}

.sidebar__menu li a:hover,
.sidebar__menu li a.page__active {
  color: var(--c-p);
}

.sidebar__menu li a p {
  font-size: 18px;
}

.sidebar__options {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile {
  width: 100%;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
  gap: 1.2rem;
  /* border-top: 2px solid rgba(189, 197, 205, 0.5);
  border-bottom: 2px solid rgba(189, 197, 205, 0.5); */
}

.profile img {
  width: 43px;
  height: 43px;
  margin-left: -0.6rem;
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
  outline: 2px solid var(--c-p);
  outline-offset: 2px;
}

.profile__details {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.profile__details h4 {
  font-size: 17px;
  color: #fff;
}

.profile__details a {
  font-size: 15px;
  color: #687684;
  transition: color 0.3s ease-in-out;
}
.profile__details a {
  font-size: 15px;
  color: #687684;
  transition: color 0.3s ease-in-out;
}

.profile__details a:hover {
  color: var(--c-p);
}

.community__terms {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.community__classic {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.community__classic a {
  font-size: 14px;
  color: #687684;
}

.copyright {
  width: 100%;
  text-align: left;
  color: #687684;
  font-size: 14px;
}

.hidden {
  display: none;
}

@media all and (max-width: 1100px) {
  .dashboard__sidebar.active {
    width: 280px;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
  }

  .hidden {
    display: block;
  }

  .dashboard__sidebar.hide {
    position: absolute;
    top: 0;
    left: -900px;
  }

  .navbar__mobile {
    display: flex;
    width: 100%;
    height: 76px;
    background-color: #000000;
    border-bottom: 0.6px solid rgba(109, 109, 109, 0.423);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    justify-content: space-between;
    align-items: center;
  }

  .navbar__mobile .sidebar__logo img {
    width: 56px;
    aspect-ratio: 1/1;
    height: 100%;
    object-fit: contain;
  }

  .navbar__mobile {
    padding: 0 0.8rem;
  }

  .navbar__mobile .sidebar__logo {
    width: auto;
    display: flex;
    gap: 0.2rem;
    justify-content: flex-end;
    cursor: default;
  }

  .navbar__mobile .sidebar__logo p {
    width: 100px;
  }

  .smscreen__menu {
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 28px;
    color: var(--c-p);
    cursor: pointer;
  }
}
