@import '/src/index.css';

.about__page {
  width: 100%;
  max-width: 100%;
  padding: 1.4rem 0 0;
  background-color: #f2eee9;
}

.about__section1 {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 3rem auto 0rem;
  padding: 3rem 0 4rem;

  background-color: #f2eee9;
  position: relative;
}

.pink__gradient1 {
  max-width: 100%;
  width: 1100px;
  height: 700px;
  position: absolute;
  top: -10rem;
  right: 0rem;
  overflow: hidden;
}

.section1__center {
  width: 1200px;
  max-width: 100%;
  margin: auto;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
}

.section1__side1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: flex-start; */
  column-gap: 2.4rem;
}
.section1__side1 img {
  width: 90%;
  height: 90%;
}
.section1__side2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2.4rem;
}
.section1__side2 h2 {
  background: linear-gradient(134deg, #455689 50%, #92d3da 60%, #455689 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.3rem;
  font-weight: 800;
  letter-spacing: 0.9px;
}
.section1__side2 p {
  color: #0f415e;
  font-size: 1rem;
  letter-spacing: 0.62px;
  line-height: 1.6rem;
  font-weight: 500;
  margin-top: -1rem;
  width: 85%;
}
/*  */
.about__section2 {
  width: 100%;
  max-width: 100%;
}
.section2__center {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1.6rem;
}
.section2__center h4,
.section3__center h4 {
  background: linear-gradient(134deg, #455689 50%, #92d3da 60%, #455689 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.05rem;
  letter-spacing: 1px;
  font-weight: 600;
}
.section2__benefits {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  column-gap: 1.6rem;
  row-gap: 1.7rem;
  margin-top: 2rem;
}
.benefit__div {
  padding: 1.6rem 1rem 0 0rem;
  height: 280px;
  /* padding-right: 1rem; */
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #455689, #92d3da, rgba(0, 0, 0, 0)) 1
    100%;
}
.right__border {
  border-left: none;
}
.section2__benefits div h3 {
  color: #c11574;
  font-size: 1.06rem;
  letter-spacing: 0.6px;
  font-weight: 600;
  /* width: 30%; */
}
.section2__benefits div p {
  color: rgba(38, 36, 59, 0.6);
  font-size: 1.06rem;
  font-weight: 500;
  letter-spacing: 0.7px;
  line-height: 1.7rem;
  margin-top: 1rem;
}
.redirect__team {
  min-width: 31%;
  height: 220px;
  background: var(
    --lilac-pink-gradient,
    linear-gradient(100deg, #9181f4 -5.85%, #ed6c7d 109.55%)
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.9rem;
  padding: 0 1.6rem;
  border-radius: 1rem;
}
.redirect__team h2 {
  color: #fff;
  font-weight: 500;
  font-size: 1.35rem;
  letter-spacing: 1.2px;
}
.arrow__icon {
  color: #fff;
  width: 25px;
  height: 25px;
}

/*  */
.about__section3 {
  width: 100%;
  max-width: 100%;
}
.section3__center {
  width: 1200px;
  max-width: 100%;
  padding-bottom: 2rem;
  margin: 3rem auto 0rem auto;
  padding: 0 1.6rem;
}
.section3__members {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-top: 3rem;
}
.section3__imageblock {
  width: 100%;
  /* height: 100%; */
  max-height: 380px;
  overflow: hidden;
  display: flex;
  /* justify-content: flex-end; */
  position: relative;
  z-index: 1;
}

.section3__subImageBlock {
  width: 100%;
  height: 100%;
  max-width: 100%;

  max-height: 100%;
  position: relative;
  z-index: 10;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.42%, #000 112.9%);
}

.photo__overlay {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  background: transparent;
  transition: all 0.5s ease-in-out;
}

.photo__overlay h3 {
  position: absolute;
  top: 7rem;
  padding: 0 1rem;

  color: #fff;
  font-size: 1.6rem;
  letter-spacing: 0.7px;
}

.name__block {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 1.3rem;
  position: absolute;
  bottom: 1.3rem;
  z-index: 30;
  flex-direction: row;
}

.name__block div:nth-child(1) {
  display: flex;
  flex-direction: column;
}

.social__icons {
  width: 23px;
  height: 23px;
  color: #fff;
  /* opacity: 0.2; */
  /* border-radius: 50%; */
}

.name__block div:nth-child(1) h2 {
  color: #ffff;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.6px;
}
.name__block div:nth-child(1) h6 {
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.name__block div:nth-child(2) {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.section3__subImageBlock img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.section3__imageblock:hover .photo__overlay {
  opacity: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.42%, #000 112.9%);
}

@media all and (max-width: 1080px) {
  .section3__members {
    grid-template-columns: 33% 33% 33%;
  }
  .section3__imageblock {
    max-height: 340px;
    overflow: hidden;
  }
}

@media all and (max-width: 880px) {
  .section2__benefits {
    grid-template-columns: 48% 48%;
  }
  .redirect__team {
    min-width: 50%;
  }
  .section3__center {
    padding: 0 1rem;
  }
}
@media all and (max-width: 780px) {
  .section1__center {
    flex-direction: column;
    row-gap: 3rem;
  }
  .section1__side1 {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .section1__side1 img {
    width: 55%;
  }
  .section1__side2 {
    align-items: center;
    width: 100%;
  }
  .section1__side2 p {
    text-align: center;
  }
  .section3__members {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    justify-content: center;
  }
  .section3__imageblock {
    width: 250px;
    max-height: 300px;
    overflow: hidden;
  }
}
@media all and (max-width: 560px) {
  .section2__center,
  .section3__center {
    padding: 0 0.8rem;
  }
  .section1__side1 img {
    width: 80%;
  }
  .section2__benefits {
    grid-template-columns: 98%;
  }
  .benefit__div {
    height: 240px;
    padding: 0.5rem 0.5rem 0 0;
  }
  .redirect__team {
    min-width: 96%;
    height: 170px;
  }
}
