@import '/src/index.css';

.app__navbar {
  position: relative;
  width: 1200px;
  max-width: 95%;
  height: 86px;
  margin: auto;
  border-radius: 20px;
  border-radius: 23px;
  background: var(
    --dark-blue-gradient,
    linear-gradient(100deg, #000 7.38%, #455689 109.55%, #455689 109.55%)
  );
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 2.4rem;
  z-index: 100;
}
.displayNone {
  display: none;
}
.app__navbar .gradientOne {
  position: absolute;
  top: -21.3rem;
  left: -26.4rem;
  width: 700px;
  height: 700px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(50, 72, 213, 0.2) 0%,
    rgba(1, 0, 255, 0) 92.71%
  );
}
.app__navbar .gradientTwo {
  position: absolute;
  width: 100%;
  height: 900px;
  /* right: -55rem; */
  left: 69%;
  top: -20rem;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(50, 72, 213, 0.2) 0%,
    rgba(1, 0, 255, 0) 92.71%
  );
}
.nav__center {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  padding: 0 2rem;
}

.nav__logo a img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}
.nav__sidebar ul {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}
.nav__sidebar ul li {
  list-style-type: none;
}
.nav__sidebar ul li a {
  text-decoration: none;
  color: #fff;
  font-family: var(--f-p);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.36px;
  transition: color 0.3s ease;
}
.nav__sidebar ul li a:hover {
  color: #92d3da;
  /* background: linear-gradient(134deg, #455689 50%, #92d3da 60%, #455689 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-weight: 500;
  cursor: pointer;
}
.nav__menu {
  display: none;
}
/* Media Queries */
@media all and (max-width: 600px) {
  .nav__menu {
    /* display: none; */
    display: block;
    color: var(--c-w);
  }
  .nav__sidebar ul {
    display: none;
  }
  .nav__sidebar .nav__sidebar__ul {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    right: 0;
    bottom: -3.5rem;
    background: var(
      --dark-blue-gradient,
      linear-gradient(100deg, #000 7.38%, #455689 109.55%, #455689 109.55%)
    );
    box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
    padding: 0.612rem 0.8rem;
    border-radius: 10px 10px 10px 10px;

    /* display: none; */
  }
}
@media all and (max-width: 500px) {
  .nav__logo {
    width: 60px;
    height: 60px;
  }
  .nav__name p {
    font-size: 1.1rem;
  }

  .app__navbar {
    padding: 0.4rem 1rem;
  }
  .nav__sidebar .nav__sidebar__ul {
    bottom: -3.8rem;
    right: -0.9rem;
  }
  .nav__sidebar ul li a {
    font-size: 0.95rem;
  }
  .nav__logo a img {
    width: 50px;
    height: 50px;
    margin-top: 0.3rem;
  }
  .app__navbar {
    height: 62px;
    display: flex;
    align-items: center;
  }
}

@media all and (max-width: 320px) {
  .app__navbar {
    padding: 0.4rem 0.8rem;
  }

  .nav__name p {
    font-size: 20px;
  }
}
