.page__404 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  justify-content: flex-start;
  align-items: center;
}

.text__404 {
  width: 100%;
  min-height: 70vh;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.text__404 h1 {
  font-size: 2.4rem;
  letter-spacing: 0.3px;
  text-align: center;
}

.text__404 p {
  font-size: 1rem;
  text-align: center;
}

.text__404 a {
  padding: 0.6rem;
  font-size: 1rem;
  color: #232323;
  background-color: #FFF;
  border-radius: 12px;
}