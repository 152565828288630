.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: #fff;
    padding: 2vw;
    border-radius: 10px;
    max-width: 35vw;
    width: 90%;
    position: relative;
  }
  .modal_header{
    background: var(
        --dark-blue-gradient,
        linear-gradient(100deg, #000 7.38%, #455689 109.55%, #455689 109.55%)
      ); 
    display: flex;
    align-items: center;
    color: white;
    border-radius: 12px;
    padding: 2vh;
    margin-bottom: 2vh;
  }
  .modal_content {
    text-align: left;
  }
  .modal_content p{
    font-size: large;
  }
  .tags{
    display: flex;
    margin-bottom: 2vh;
}
.tag_item{
    font-size:0.7em;
    color: white;
    background-color: rgba(52, 87, 123, 0.68);
    border-radius: 10px;
    padding: 1vh 1vw;
    margin-right: 0.5vw;
    width:auto;
}
.modal_content h4{
    margin: 1vh 0;
}
  .close_button {
    position: absolute;
    top: 8px;
    right: 10px;
    border: none;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
  }
.close_button:hover{
    color: red;
}
  