/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap');
:root {
  --c-b: #232323;
  --c-w: #ffffff;
  --c-p: #e89f40;

  --f-p: 'DM Sans', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #687684;
}

li {
  list-style-type: none;
}

.app {
  max-width: 100%;
  /* width: 1200px; */
  /* margin: auto; */
  width: 100%;
  height: 100%;
  background-color: #000;
}

.ql-container {
  height: 500px !important;
  overflow-y: auto;
}
.layout__main {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  position: relative;
}

@media all and (max-width: 600px) {
  * {
    cursor: default;
  }

  p,
  img {
    user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
  }

  .ql-container {
    height: 400px !important;
  }

  .ql-toolbar {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
}
